.btn-search {
  @apply w-[120px];
}

.btn {
  min-width: 120px;
}
.ant-btn-no-style{
  &:focus {
    @apply text-[#000000d9];
    @apply border-[#d9d9d9];
  }
}
.btn-secondary{
  @apply bg-primary;
  @apply text-white;
  @apply border-primary;
  &:hover {
    @apply bg-[#89a4b4];
    @apply border-[#89a4b4];
    @apply text-white;
  }
  &:focus {
    @apply bg-primary;
    @apply text-white;
  }
}

.btn-danger{
  @apply bg-[#FF4D4F];
  @apply text-white;
  &:hover {
    @apply bg-[#ff4d4fcc];
    @apply border-[#ff4d4fcc];
    @apply text-white;
  }
  &:focus {
    @apply bg-[#FF4D4F];
    @apply text-white;
  }
}

.btn-slate {
  @apply bg-[#6991A7];
  @apply border-[#6991A7];

  &:hover {
    @apply bg-[#89a4b4];
    @apply border-[#89a4b4];
  }

  &:active {
    @apply bg-[#89a4b4];
    @apply border-[#89a4b4];
  }

  &:focus {
    @apply bg-[#89a4b4];
    @apply border-[#89a4b4];
  }
}