.search-container {
  position: relative;
  @apply p-4;
  @apply border;
  @apply border-solid;
  @apply border-[#d9d9d9];
  @apply bg-[#fbfbfb];
  @apply rounded;
}

.detail-info-container {
  &>.header {
    @apply mb-2;
    line-height: 32px;

    & .code {
      @apply text-lg;
      @apply font-bold;
    }
  }

  &>.body {
    @apply p-2;
    @apply border border-solid rounded-sm border-gray-300;
    @apply bg-white;
  }

  & .ant-form-item {
    @apply text-base;
    margin-bottom: 8px;
  }

  & .ant-form-item-label>label {
    @apply text-gray-500;
  }
}