@import '~antd/dist/reset.css';

.ant-tag {
    margin-bottom: 2px;
}
.ant-select-selector{
    text-align: initial;
}
.ant-form-item-control-input{
    text-align: initial;
}

.ant-picker-body{
    tr{
        vertical-align: top
    }

}
.no-ant-fullcalendar-header{
    .ant-picker-calendar-header {
        display: none !important;
    }
}

  
