@import './_variable.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './components/menu.css';
@import './components/button.css';
@import './components/tabs.css';
@import './components/card.css';
@import './components/form.css';
@import './components/table.css';
@import './components/dropdown.css';
@import './components/container.css';
@import './components/datePicker.css';

.spin {
    font-size: 24px;
    @apply text-primary;
}

.page-breadcrumb {
    margin-top: -15px;
    margin-bottom: 10px;
}

.btn-primary {
    @apply w-[120px];
}

.erp-table {
    & .ant-table-thead > tr > th {
        @apply bg-primary text-gray-100;
        @apply font-normal;
    }

    & .ant-table-thead > tr > th.ant-table-column-sort {
        background: rgb(64 96 135 / var(--tw-bg-opacity));
    }
    & .ant-table-thead > tr > th.ant-table-column-has-sorters:hover {
        background: rgb(64 96 135 / var(--tw-bg-opacity));
    }
    & .ant-table-column-sorter {
        color: #fff;
    }
    & .ant-table-column-sorter-up.active {
        color: #39c1b6;
    }
    & .ant-table-column-sorter-down.active {
        color: #39c1b6;
    }
    & .ant-table-column-sorters:hover .ant-table-column-sorter {
        color: #fff;
    }
    & td.ant-table-column-sort {
        background: #ffffff;
    }
}

.product-table {
    & .ant-table-tbody > tr > td {
        @apply text-xs;
    }

    & .ant-table-thead > tr > th {
        @apply text-xs;
        @apply bg-slate-600 text-gray-100;
        @apply font-normal py-2 px-0 text-center;
    }
}
.table-row-goal {
    background: #f2f4fc !important;
}

.td-bg-yellow {
    background: #fffce4;
}
.detail-divider {
    @apply mt-4 mb-0;
    @apply border-gray-500;
}

::-webkit-scrollbar {
    width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #39c1b6;
    border-radius: 10px;
}

.ant-input-disabled.ant-input[disabled] {
    @apply text-[#000];
}

.ant-table-wrapper .ant-table-thead > tr > td {
    background: #406087;
}
