.erp-table {
  & .ant-table-thead>tr>th {
    @apply bg-primary text-gray-100;
    @apply font-normal;
    @apply text-center;
  }
}

.product-table {
  & .ant-table-thead>tr>th {
    @apply bg-slate-600 text-gray-100;
    @apply font-normal py-2 px-0 text-center;
    @apply text-center;
  }
}