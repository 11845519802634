.side-menu {
    & .ant-menu-item-active {
        color: #fff !important;
    }
    & .ant-menu-item-selected {
        background: #39c1b6;
        background-color: #39c1b6 !important;
        color: #334454 !important;
    }

    & .ant-menu-submenu-title {
        color: #fff !important;
    }

    & .ant-menu-submenu-title:hover {
        color: #fff !important;
    }

    & .ant-menu > .ant-menu-submenu-selected > .ant-menu-submenu-title {
        color: #fff !important;
    }
}
