.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    /* @apply text-white !important; */
    @apply font-bold !important;
    text-shadow: none;
}

/* 主要頁面分頁 tab */
.page-tabs {
    & > .ant-tabs-nav {
        @apply bg-[#f5f5f5] pl-1;
        @apply fixed top-[52px] left-0 right-0 z-50  ease-in duration-300;
        @apply border-0 border-b border-solid border-b-gray-300;
        & > .ant-tabs-nav-wrap {
            & > .ant-tabs-nav-list {
                .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                    @apply text-white !important;
                }
                & .ant-tabs-tab:hover {
                    color: #f3c71d;
                }
            }
        }
    }

    & .ant-tabs-tab {
        @apply bg-primary !important;
        @apply text-white;
        transition: all 0s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    }
    &.ant-tabs-tab-btn {
        color: white;
    }

    & .ant-tabs-tab.ant-tabs-tab-active {
        @apply bg-secondary !important;
    }

    & .ant-tabs-nav-wrap {
        @apply pt-1;
    }

    & .ant-tabs-top > .ant-tabs-nav {
        @apply m-0;
    }

    & .ant-tabs-content-holder {
        @apply p-4 overflow-auto min-h-[calc(100vh_-_130px)];
    }
}

#page-tabs-trigger {
    display: none;

    &:checked ~ div.page-tabs-box > div.page-tabs .ant-tabs-nav {
        left: 80px;
    }

    & ~ div.page-tabs-box > div.page-tabs .ant-tabs-nav {
        left: 200px;
    }
}

/* 詳細頁面分頁 tabs */
.detail-tabs {
    & .ant-tabs-tab {
        min-width: 120px;
        @apply p-3;
        @apply text-gray-600 !important;
        @apply bg-gray-300 !important;
    }

    & .ant-tabs-tab.ant-tabs-tab-active {
        @apply text-white !important;
        @apply bg-[#47505E] !important;
        border-bottom-color: #47505e !important;
        border-radius: 2px;
    }

    & .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 15px;
    }

    & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        @apply text-white !important;
        @apply font-normal !important;
    }

    & .ant-tabs-tab .anticon {
        margin-right: 8px;
    }

    & .ant-tabs-content-holder {
        @apply mt-6;
        @apply p-0;
    }

    & > .ant-tabs-nav {
        left: 0px !important;
        .ant-tabs-nav-wrap {
            @apply w-full justify-center;
        }
    }
}
.performance-tabs {
    & .ant-tabs-tab {
        min-width: 120px;
        @apply p-3;
        @apply text-gray-600 !important;
        @apply bg-gray-300 !important;
    }

    & .ant-tabs-tab.ant-tabs-tab-active {
        @apply text-white !important;
        @apply bg-[#47505E] !important;
        border-bottom-color: #47505e !important;
        border-radius: 2px;
    }

    & .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 15px;
    }

    & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        @apply text-white !important;
        @apply font-normal !important;
    }

    & .ant-tabs-tab .anticon {
        margin-right: 8px;
    }

    & .ant-tabs-content-holder {
        @apply mt-3;
        @apply p-0;
    }
    & > .ant-tabs-nav {
        left: 0px !important;
    }
}
.dashboard-tabs {
    & .ant-tabs-tab {
        /* min-width: 120px; */
        @apply p-3;
        @apply text-gray-600;
        @apply bg-transparent !important;
        border-bottom: 1px solid #f0f0f0 !important;
    }
    & .ant-tabs-tab.ant-tabs-tab-active {
        @apply text-[#1677ff];
        @apply bg-white !important;
    }
    & .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 15px;
    }

    & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        @apply font-normal !important;
    }

    & .ant-tabs-tab .anticon {
        margin-right: 8px;
    }

    & .ant-tabs-content-holder {
        @apply mt-3;
        @apply p-0;
    }
    & > .ant-tabs-nav {
        left: 0px !important;
    }
}

.dashboard-card {
    .ant-card-body {
        padding: 5px 16px 16px 16px;
    }
    .ant-tabs-nav-wrap {
        text-align: center;
    }
    & > .ant-card-head {
        & > .ant-tabs {
            & > .ant-tabs-content-holder {
                min-height: 4px !important;
            }
        }
    }
}
